/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Images
import Logo from 'img/logo.inline.svg'
import MobileBG from 'img/logobg.inline.svg'
import ArrowDown from 'img/down-arrow.inline.svg'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import NavLink from 'components/shared/NavLink'
import { motion } from 'framer-motion'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  ${(props) =>
    props.isSticky
      ? css`
          background-color: ${props.theme.color.primary};
        `
      : css`
          background: rgb(49, 102, 151);
          background: linear-gradient(
            180deg,
            rgba(49, 102, 151, 1) 0%,
            rgba(49, 102, 151, 0.7651435574229692) 38%,
            rgba(255, 255, 255, 0) 100%
          );
        `}
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)<{ isSticky: boolean }>`
  position: relative;

  z-index: 9999;
  background-color: ${({ theme }) => theme.color.primary};

  ${(props) =>
    !props.isSticky &&
    css`
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `};

  & > svg {
    transition: opacity 0.3s ease-in-out;
    @media (min-width: 1200px) {
      height: 39.5px;
      width: 133px;
    }
    @media (max-width: 1199px) {
      height: 32.5px;
      width: 111px;
    }
  }
  &:hover > svg {
    opacity: 0.75;
  }

  @media (min-width: 1200px) {
    padding: 1.9rem;
  }
  @media (max-width: 1199px) {
    padding: 1.9rem 1rem;
    ${(props) =>
      !props.isSticky &&
      css`
        margin-top: -0.4rem;
      `};
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
          icon {
            localFile {
              publicURL
            }
          }
          phone
        }
      }
    }
  `)

  return (
    <StyledHeader isSticky={isSticky}>
      <Container className="container">
        <Brand to="/" isSticky={isSticky}>
          <Logo />
        </Brand>

        <MenuMobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <MenuDesktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 150px 0 0;

  & > ul {
    position: relative;
    background: transparent;
    border-radius: 12px;
    padding-left: 0;
    z-index: 2;
    list-style-type: none;
    text-align: center;
    width: 80%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: 24px;
        display: block;
        padding: 10px 0;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 40px;
  top: 30px;
  text-align: center;
  z-index: 3;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.dark};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MobileBGWrapper = styled.div`
  position: absolute;
  left: -6px;
  bottom: 48px;
`

interface MenuMobileProps {
  fields: any
  isSticky?: boolean
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>Menu</NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <MobileBGWrapper>
            <MobileBG />
          </MobileBGWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;

  & li {
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }
`

interface MenuDesktopProps {
  fields: any
  isSticky?: boolean
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex ps-2 ps-xl-4">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  position: relative;
  & > a {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};

    &:hover {
      @media (min-width: 992px) {
        color: ${({ theme }) => theme.color.light};
      }
      @media (max-width: 991px) {
        color: ${({ theme }) => theme.color.primary};
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      bottom: -5px;
      left: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      @media (min-width: 992px) {
        background-color: ${(props) => props.theme.color.light};
      }
    }

    &[aria-current] {
      &:before {
        visibility: visible;
        width: 100%;
      }
      @media (max-width: 991px) {
        color: ${({ theme }) => theme.color.primary};
      }
    }

    &:hover:before {
      visibility: visible;
      width: 100%;
    }

    @media (min-width: 992px) {
      color: ${(props) => props.theme.color.light};
    }
    @media (max-width: 991px) {
      color: ${(props) => props.theme.color.dark};
    }
  }
`

const ItemWithSubmenu = styled.div`
  & svg {
    width: 10px;
    height: 10px;
    @media (min-width: 992px) {
      fill: ${({ theme }) => theme.color.light};
    }
  }
`

const SubmenuWrapper = styled(motion.div)`
  position: absolute;
  z-index: 999999;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;

  @media (min-width: 992px) {
    left: -80px;
    min-width: 220px;
    top: 25px;
  }
  @media (max-width: 991px) {
    min-width: 300px;
    top: 40px;
    left: -50%;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  @media (max-width: 1200px) {
    & a,
    & button {
      font-size: 12px;
    }

    & img {
      width: 16px;
      height: 16px;
    }
  }
`

const ItemWrapper = styled.div`
  @media (max-width: 991px) {
    min-width: 150px;
  }
`

interface MenuProps {
  fields: any
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [submenuHover, setSubmenuHover] = useState<number | null>(null)

  const subMenuAnimations = {
    enter: {
      opacity: 1,
      rotateX: 0,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      display: 'block',
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <>
      {fields.header.menuHeader.map(
        ({ link: { url, title }, submenu }: any, index: number) => {
          const hovered: boolean = submenuHover === index

          if (submenu) {
            return (
              <ItemWithSubmenu className="position-relative d-flex align-items-center justify-content-center">
                <ItemWrapper className="position-relative d-inline-flex align-items-center justify-content-center">
                  <motion.div
                    onMouseEnter={() => setSubmenuHover(index)}
                    onMouseLeave={() => setSubmenuHover(null)}
                    whileTap={() => setSubmenuHover(index)}
                    onTapCancel={() => setSubmenuHover(null)}
                    className="d-flex align-items-center"
                  >
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <Item key={index}>
                      <NavLink to={url}>{title}</NavLink>
                    </Item>

                    <ArrowDown className="ms-2 mt-1" role="button" />
                    <SubmenuWrapper
                      initial="exit"
                      animate={hovered ? 'enter' : 'exit'}
                      variants={subMenuAnimations}
                    >
                      <Submenu items={submenu} />
                    </SubmenuWrapper>
                  </motion.div>
                </ItemWrapper>
                {/* eslint-disable-next-line react/no-array-index-key */}
              </ItemWithSubmenu>
            )
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index}>
              <NavLink to={url}>{title}</NavLink>
            </Item>
          )
        }
      )}
      <StyledButtonPrimary
        to={`tel:${fields.header.phone}`}
        className="mb-4 mb-lg-0 mt-3 mt-lg-0"
      >
        <span className="d-flex align-items-center">
          <Plaatjie image={fields.header.icon} alt="" />
          <span className="ms-xl-3 ms-1">{fields.header.phone}</span>
        </span>
      </StyledButtonPrimary>
    </>
  )
}

const SubmenuItem = styled(motion.div)`
  @media (min-width: 992px) {
    line-height: 60px;
  }

  @media (max-width: 991px) {
    line-height: 40px;
    text-align: left;
    & > a {
      font-size: 18px !important;
      background-color: white;
    }
  }
`

interface SubmenuProps {
  items: Array<any>
}

const Submenu: React.FC<SubmenuProps> = ({ items }) => (
  <>
    {items.map((item, index) => (
      <SubmenuItem
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        initial={{
          background: '#FFFFFF',
        }}
        whileHover={{
          background: '#FF7700',
        }}
        transition={{ type: 'spring' }}
      >
        <NavLink to={item.link.url} className="d-flex w-100 ps-4">
          {item.link.title}
        </NavLink>
      </SubmenuItem>
    ))}
  </>
)

export default Header
