import React from 'react'
import styled from 'styled-components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const BreadcrumbContainer = styled.div`
  & ol {
    padding: 0;
    margin: 0;
    display: flex;
  }

  & li {
    list-style: none;
  }

  & a {
    display: block;
    font-size: 16px;
    line-height: 21px;
    opacity: 0.4;

    &[aria-current='page'] {
      opacity: 1;
    }

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      opacity: 1;
    }
  }

  .breadcrumb__separator {
    margin: -5px 8px;
    font-size: 20px;
    opacity: 0.4;
  }

  .breadcrumb__list {
    display: flex;
    flex-wrap: wrap;
  }
`

interface BreadcrumbProps {
  pageContext?: any
  label?: string
  className?: string
}

const BreadcrumbProvider: React.FC<BreadcrumbProps> = ({
  pageContext,
  label,
  className = '',
}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  crumbs.forEach((element: any) => {
    // eslint-disable-next-line no-param-reassign
    element.crumbLabel = element.crumbLabel.replace(/-/g, ' ')
  })

  return (
    <BreadcrumbContainer className="p-3">
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" > "
        crumbLabel={label}
        className={className}
      />
    </BreadcrumbContainer>
  )
}

export default BreadcrumbProvider
