import React from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import BreadcrumbProvider from 'components/elements/Misc/Breadcrumb'
import ParseContent from 'components/shared/ParseContent'

const Image = styled(Plaatjie)<{ type: string }>`
  ${(props) =>
    props.type === 'landing'
      ? css`
          @media (min-width: 576px) {
            height: 72vh;
            min-height: 720px;
            max-height: 980px;
          }
          @media (max-width: 575px) {
            height: 450px;
          }
        `
      : css`
          height: 500px;
          @media (max-width: 575px) {
            height: 350px;
          }
        `}
  max-width: 2000px;
  width: 100%;
`

const Block = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: 768px) {
    margin-top: -9.5rem;
  }
  @media (max-width: 767px) {
    margin-top: -5.5rem;
  }
  @media (max-width: 575px) {
    margin-top: -2.5rem;
  }

  & h1 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    border-bottom: 1px solid ${({ theme }) => theme.color.grey};
    @media (min-width: 992px) {
      font-size: 28px;
    }
  }
`

interface BannerDetailprops {
  image: any
  title: string
  description?: string
  pageContext?: any
  type: string
}

const BannerDetail: React.FC<BannerDetailprops> = ({
  image,
  title,
  description,
  pageContext,
  type,
}) => (
  <section className="mb-5 pb-lg-5">
    <div className="d-flex justify-content-center">
      <Image alt="banner" image={image} type={type} />
    </div>
    <div className="container h-100">
      <div className="row">
        <div className="col-lg-7 col-xl-5">
          <Block className="px-md-5 py-md-4 px-3 py-2">
            <h1 className="p-3">{title}</h1>
            <BreadcrumbProvider pageContext={pageContext} />
            {description && (
              <ParseContent content={description} className="ps-3 pb-4" />
            )}
          </Block>
        </div>
      </div>
    </div>
  </section>
)

export default BannerDetail
